<template lang="pug">
Modal(:show="true", :disable_close="true")
  CardPayments
    template(v-slot:content)
      .head Procesando Pago
      .sub-head Estamos verificando el pago, espere..
      .spinner
        CircleLoader
</template>
<script>
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import PaymentsRepository from "@/app/repository/payments.repository";

export default {
  name: "ProcessPayment",
  setup() {
    const route = useRoute();
    const store = useStore();
    const query = route.query;

    const processPayment = async (cc_id) => {
      PaymentsRepository.processPayment(cc_id)
        .then(({ error, requires_action, payed }) => {
          console.log({ error, requires_action, payed });
          if (requires_action) {
            window.location.href = requires_action;
          } else if (payed) {
            // await verificationPayment(payed);
          } else if (error) {
            //await this.errorPayment();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const verificationPayment = async (payment_id) => {
      PaymentsRepository.verificationPayment(payment_id)
        .then(({ access_token }) => {
          store.dispatch("authStore/autoLogin", access_token);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    if (query.payment_id) {
      verificationPayment(query.payment_id);
    } else if (query.order) {
      processPayment(query.order);
    }
  },
};
</script>
<style lang="scss" scoped>
.head {
  @apply text-xl font-bold text-gray-500 text-center;
  span {
    @apply text-green-600;
  }
}

.sub-head {
  @apply text-sm font-medium text-gray-500 text-center;
}
.spinner {
  min-height: 240px;
  @apply pb-4 pt-4 flex justify-center items-center;
}
</style>