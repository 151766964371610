import { ApiAxios } from './repository.config';

const RESOURCE = '/payments';

export default {
  async getIframePayment(user_id: string, subscription_id: string) {
    try {
      const { data } = await ApiAxios().post(RESOURCE + '/checkout', {
        user_id,
        subscription_id
      });
      return data;
    } catch (e) {
      return e.response.data;
    }
  },

  async processPayment(cc_id: string) {
    try {
      const { data } = await ApiAxios().post(RESOURCE + '/process', {
        cc_id
      });
      return data;
    } catch (e) {
      return e.response.data;
    }
  },

  async verificationPayment(payment_id: string) {
    try {
      const { data } = await ApiAxios().post(RESOURCE + '/verification', {
        payment_id
      });
      return data;
    } catch (e) {
      return e.response.data;
    }
  },

  async getLiteDataPayment(paymentId: string) {
    try {
      const { data } = await ApiAxios().get(RESOURCE + '/lite-' + paymentId);
      return data;
    } catch (e) {
      return e.response.data;
    }
  }
};
